import React from 'react';

const LogoIntima = (props: React.PropsWithChildren<React.SVGProps<SVGSVGElement>>) => (
  <svg
    {...props}
    xmlns="http://www.w3.org/2000/svg"
    version="1.1"
    x="0px"
    y="0px"
    xmlSpace="preserve"
    viewBox="27.75 183.3 513.5 163.18"
    width="98.5"
    height="31.5"
  >
    <switch>
      <g>
        <g>
          <g>
            <path
              fill="#221B72"
              className="st0"
              d="M30.83,200.52c-1.01,0-1.99,0.1-2.94,0.28l-0.14,0.13v122.12c0,0-0.32,21.47,15.45,21.47      c1,0,1.98-0.1,2.94-0.29l0.14-0.12V218.48C46.27,218.48,46.55,200.52,30.83,200.52z"
            />
          </g>
          <g>
            <path
              fill="#221B72"
              className="st0"
              d="M244.5,237.68v86.25c0,0-0.3,20.15,14.5,20.15c0.94,0,1.87-0.09,2.76-0.27l0.13-0.12V237.68H244.5z"
            />
          </g>
          <g>
            <path
              fill="#221B72"
              className="st0"
              d="M158.72,280.1c0-26.38-14.27-44.94-39.25-44.94c-12.29,0-22.79,5.37-29.74,14.23l0.01-0.22      c-1.8-6.79-7.21-11.72-13.6-11.72c-0.88,0-1.75,0.1-2.59,0.27l-0.08,0.12v106.18h16.85v-64.95c0-15.87,11.3-27.83,26.77-27.83      c16.26,0,24.98,11.96,24.98,28.24v40.85h0.01v6.42c0,0-0.25,17.53,13.85,17.53c0.91,0,1.78-0.1,2.63-0.28l0.13-0.12v-23.55h0.04      V280.1z"
            />
          </g>
          <g>
            <path
              fill="#221B72"
              className="st0"
              d="M202.09,224.01v13.75h23.19v14.64h-23.19v52.86c0,12.72,5.1,18.99,12.28,21.46      c10.8,2.4,11.32,17.3,11.32,17.3c-23.79,2.89-40.24-11.75-40.24-38.76v-52.86h-13.88v-14.64h13.88v-25.61l0.26-0.11      c0.85-0.19,1.72-0.28,2.61-0.28c6.47,0,11.93,4.98,13.76,11.85L202.09,224.01z"
            />
          </g>
          <g>
            <path
              fill="#221B72"
              className="st0"
              d="M432.98,278.24c0-26.38-14.27-43.09-37.86-43.09c-14.67,0-25.57,7.22-31.91,18.14      c-6.35-11.34-17.05-18.14-31.52-18.14c-11.12,0-20.52,5.2-26.86,13.81l-0.37,0.07c-1.8-6.79-7.21-11.72-13.6-11.72      c-0.88,0-1.75,0.1-2.58,0.28l0,0.71v105.7h16.85v-64.95c0-15.87,10.3-27.83,24.18-27.83c15.06,0,22.99,10.1,22.99,26.59v34.84      h0.04v14.08c0,0-0.25,17.53,13.85,17.53c0.91,0,1.78-0.1,2.63-0.28l0.13-0.12v-31.21h0.01v-33.61      c0-15.87,10.1-27.83,23.98-27.83c15.66,0,23.19,10.1,23.19,26.59v22.73h-0.01v26.19c0,0-0.26,17.53,14.04,17.53      c0.92,0,1.8-0.1,2.66-0.28l0.13-0.12v-33.39h0.02V278.24z"
            />
          </g>
          <g>
            <path
              fill="#221B72"
              className="st0"
              d="M500.02,235.15c-23.91,0-41.53,11.54-45.28,29.73c-0.24,1.14,6.68-0.26,15.84-3.89      c4.59-1.82,15.59-9.75,29.24-9.75c17.25,0,24.98,6.39,24.98,14.84c0,8.45-8.13,13.19-33.1,14.42      c-26.37,1.45-41.63,11.35-41.63,33.2c0,22.88,18.83,32.78,39.84,32.78c10.37,0,24.25-3.11,35.59-12.05l0.34,0.39      c2.39,5.29,7.14,8.9,12.61,8.9c0.88,0,1.75-0.1,2.58-0.28l0.22-0.47v-74.84C541.25,248.35,527.37,235.15,500.02,235.15z       M524.8,303.2c-0.4,19.17-16.85,27-34.29,27c-13.09,0-23.79-4.94-23.79-16.49c0-8.86,4.96-16.9,27.16-18.35      c12.1-0.83,24.18-2.27,30.93-8.45V303.2z"
            />
          </g>
          <path
            fill="#221B72"
            className="st0"
            d="M250.66,200.98c5.75-7.57,25.18-17.68,25.18-17.68s0.59,17.94-9.94,30.06     c-6.13,7.07-19.38,7.95-19.38,7.95S243.21,210.78,250.66,200.98z"
          />
        </g>
      </g>
    </switch>
  </svg>
);

export default LogoIntima;
